<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <mapas-sequenciamento-eletrico-data-table
          :items="items"
          :loading="loading"
          @rechargeTable="getMapasSequenciamentosEletricos"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MapasSequenciamentoEletricoService from '@/services/MapasSequenciamentoEletricoService';
import MapasSequenciamentoEletricoDataTable from '@/components/perdas-tecnicas/sequenciamento-eletrico/mapas/MapasSequenciamentoEletricoDataTable';
import refreshDataMixins from '@/mixins/refreshDataMixins';

export default {
  mixins: [refreshDataMixins],
  components: {
    MapasSequenciamentoEletricoDataTable
  },
  data: () => ({
    items: [],
    loading: false
  }),
  created() {
    this.refreshData(this.getMapasSequenciamentosEletricos);
  },
  methods: {
    getMapasSequenciamentosEletricos() {
      this.loading = true;
      MapasSequenciamentoEletricoService.getAll()
        .then(({ data }) => (this.items = data.data))
        .finally(() => (this.loading = false));
    }
  }
};
</script>
